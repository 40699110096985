<template>
  <div v-if="loaded" class="start-a-business">
    <progress-steps
      :subtitle="subtitle"
      :progress-status="progressStatus"
    />

    <div class="main-container">
      <h2 class="sl__h2 container-header">
        Choose an entity type in {{ jurisdiction.state_province_region }}.
      </h2>
      <ct-card-deck class="container-body">
        <ct-card
          v-if="llcEntityType"
          :class="{ 'selected-payment-method': selectedPaymentMethod === 'llc' }"
          @click="entitySelectChanged(llcEntityType.id, 'llc')"
        >
          <input
            id="selectEntityTypeLLC"
            v-model="selectedEntityTypeId"
            type="radio"
            name="selectEntityType"
            class="entity-type-input"
            :value="llcEntityType.id"
          >
          <div class="card__left">
            <llc-svg class="card__image" alt="shop with awning" />
            LLC
          </div>
          <label class="sl__label card__text" for="selectEntityTypeLLC">
            Limited liability companies are simple and affordable to own,
            making them the preferred structure for new and small
            business owners. Most&nbsp;people choose an LLC.
          </label>
        </ct-card>

        <ct-card
          v-if="corporationEntityType"
          :class="{ 'selected-payment-method': selectedPaymentMethod === 'corporation' }"
          @click="entitySelectChanged(corporationEntityType.id, 'corporation')"
        >
          <input
            id="selectEntityTypeCorp"
            v-model="selectedEntityTypeId"
            type="radio"
            name="selectEntityType"
            class="entity-type-input"
            :value="corporationEntityType.id"
          >
          <div class="card__left">
            <corporation-svg class="card__image" alt="buildings" />
            Corporation
          </div>
          <label class="sl__label card__text" for="selectEntityTypeCorp">
            Corporations are more complex and expensive, but make
            excellent vehicles for formal ownership and management
            structures, and for raising large sums of capital.
          </label>
        </ct-card>
      </ct-card-deck>

      <div class="row container-body">
        <div class="col-md-6 mb-2 mb-md-0 other-entity-dropdown-col-first">
          <h5 class="sl__h5 something-else">
            Looking for something else?
          </h5>
          <p class="sl__p">
            <small class="sl__small">
              Our business guide supports additional entity types
              that you can add to your account instead.
            </small>
          </p>
        </div>
        <div class="col-md-6 other-entity-dropdown-col-last">
          <b-form-select
            v-model="selectedEntityTypeDropdownId"
            class="other-entity-dropdown"
            :options="nonLLCAndCorpEntityTypes"
            @change="entitySelectChanged($event, 'other')"
          />
        </div>
      </div>
      <div class="row container-body">
        <p class="sl__p col-md-12">
          <small class="sl__small">
            *An S corporation is an IRS tax election—it’s not an entity structure.
            The S corp election is available to most LLCs and corporations.
            We can help you file for an S corp election once you’ve formed your company.
          </small>
        </p>
      </div>
    </div>

    <button-container
      :next-button-label="nextButtonLabel"
      :show-previous-button="showPreviousButton"
      :disable-next-button="disableNextButton"
      @next="next"
    />
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'SelectEntityType',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    CtCardDeck: () => import('@/components/shared/CtCardDeck'),
    CtCard: () => import('@/components/shared/CtCard'),
    LlcSvg: () => import('@images/illustrations/stageline/LLC_1.svg'),
    CorporationSvg: () => import('@images/illustrations/stageline/Corporation_1.svg'),
    ProgressSteps: () => import('@/components/StagelineV2/domainsFirst/shared/ProgressSteps'),
    ButtonContainer: () => import('@/components/StagelineV2/domainsFirst/shared/ButtonContainer'),
  },
  mixins: [makeToastMixin],
  data () {
    return {
      subtitle: 'entity type',
      progressStatus: 40,
      nextButtonLabel: 'Next: Business Name',
      showPreviousButton: true,
      selectedEntityTypeId: null,
      selectedEntityTypeDropdownId: null,
      selectedPaymentMethod: null,
      isSelectClicked: false,
      selectedEntityType: null,
    }
  },
  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'entityType',
      'loaded',
    ]),
    ...mapGetters('entityTypes', [
      'entityTypes',
      'corporationEntityType',
      'llcEntityType',
      'nonLLCAndCorpEntityTypes',
    ]),
    disableNextButton() {
      return this.selectedEntityType == null
    },
  },
  async mounted() {
    this.setLoaded(false)
    await this.loadEntityTypes()
    this.setSelectedEntityType()
    this.showLoading()
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setCompanyEntityType',
      'setCurrentSlide',
      'setLoaded',
    ]),
    ...mapActions('entityTypes', ['getEntityTypes']),

    async loadEntityTypes() {
      if (!this.entityTypes?.length) {
        await this.getEntityTypes()
      }
    },
    setSelectedEntityType() {
      if (this.entityType !== null) {
        this.selectedEntityTypeId = this.entityType.id

        if (this.entityType.id !== this.llcEntityType.id &&
          this.entityType.id !== this.corporationEntityType.id) {
          this.selectedEntityTypeDropdownId = this.entityType.id
        }
      }
    },
    entitySelectChanged(entityTypeId, cardType) {
      // Set the selectedPaymentMethod data property to the clicked card type (teal border)
      this.selectedPaymentMethod = cardType
      // Store the selected entity type in the selectedEntityType data property
      this.selectedEntityType = cardType
      if (entityTypeId) {
        this.setEntityTypeAndNavigateToCompany(entityTypeId)
      }
    },
    setEntityTypeAndNavigateToCompany(entityTypeId) {
      const selectedEntityType = this.entityTypes.find(et => et.id == entityTypeId)

      if (selectedEntityType) {
        this.setCompanyEntityType(selectedEntityType)
      } else {
        this.errorToast('Error', 'An error has occurred when attempting to save entity type, please try again.')
      }
    },
    showLoading() {
      setTimeout(() => {  this.setLoaded(true) }, 500)
    },
    next() {
      if (!this.selectedEntityType) return

      this.setCurrentSlide('CompanyName')
    },
  },
}
</script>

<style lang="scss" scoped>
.sl__h2,
.sl__p,
.sl__small,
.sl__label {
  text-align: left;
}

.start-a-business {

  .main-container {

    .ct-card-deck {
      margin-top: -3.0em !important;
      padding-left: 0 !important;

      ul.cards {

        li.card {

          .card__left {
            font-weight: 900;
            font-size: 1.1em;
            text-align: center;

            .card__image {
              height: 3.625em !important;
            }
          }

          .card__text {
            margin-left: 1.0em;
            text-align: left;
          }
        }
      }
    }

    .container-body {
      margin: 0 0 2.0em 0;
      padding: 0 2.0em;

      .other-entity-dropdown-col-first {
        @include sl__flex(column, center, flex-start);
        margin-bottom: 0.5em;

        .something-else {
          margin: 0 0 1.0em 0;
        }
      }

      .other-entity-dropdown-col-last {
        padding-top: 0.5em;

        .other-entity-dropdown {
          width: 100%;

          ::v-deep .multiselect__tags {
            border-radius: 0.3125em !important;
          }
        }
      }
    }

    .selected-payment-method {
      border: 0.125em solid $sl__teal1;
    }

    .entity-type-input {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .start-a-business {

    .main-container {

      .container-body {
        padding-left: 1.5em;
        padding-right: 0;
      }
    }
  }
}
</style>
